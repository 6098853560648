html, body{
  /* text-align: center; */
  min-height: 100vh;
  /* max-width: 100vw; */
  /* height: auto; */
  /* width: 100vw; */
  background-color: white;
  color: black;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  font-family: Arial, Helvetica, sans-serif;
  /* font-weight: 400; */
  /* letter-spacing: 0.05rem; */
}

.App {
  text-align: center;
  min-height: 89vh;
  height: 100%;
  /* background-color: black; */
  /* color: #f6f6f6; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
  font-weight: 400;
  letter-spacing: 0.05rem;
}