body {
  background-color: white;
  color: black;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* 
@font-face {
  font-family: 'Pixeboy';
  src: url('./font/Pixeboy.ttf');
} */